import React, { useState } from 'react';
import styled from 'styled-components';

import Icon, { Icons } from '../../Icon';
import Card from '../../Card';
import themes from '../../themes/primary';
import AssetActions from './components/AssetActions';

const BOX_RADIUS = 8;

function UploadImage({
  image = '',
  label = '',
  isStack = false,
  actions = [],
  fileType,
  large,
}) {
  const [isHovered, setHovered] = useState(false);
  const iconType = fileType === 'pdf' ? Icons.faFilePdf : Icons.faFileAlt;

  return (
    <Wrapper>
      <ImageWrapper
        onMouseEnter={(e) => {
          e.preventDefault();
          setHovered(true);
        }}
        onMouseLeave={() => setHovered(false)}
        isStack={isStack}
      >
        {image ? (
          <>
            {fileType === 'video' ? (
              <Video
                src={image}
                draggable={false}
              />
            ) : (
              <Image
                src={image}
                alt=""
                draggable={false}
              />
            )}
          </>
        ) : (
          <>
            {fileType !== 'image' && fileType !== 'video' && large
              ? (
                <IconWrapper>
                  <Icon
                    icon={iconType}
                    size="10x"
                  />
                </IconWrapper>
              ) : (
                <Card center>
                  <PlaceholderIcon
                    icon={Icons.faImage}
                    size="4x"
                  />
                </Card>
              )}
          </>
        )}
        <AssetActions
          isHovered={isHovered}
          actions={actions}
        />
      </ImageWrapper>
      {!!label && <Text>{label}</Text>}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  position: relative;
`;
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 0;
  align-items: center;
  justify-content: center;
  display: flex;

  ${(props) => props.isStack && `
    left: -7px;
    top: 7px;

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 8px;
      border: 2px solid #47475b;
      top: -7px;
      left: 7px;
      z-index: -1;
      background-color: ${themes.colors.backgroundDark};
    }

    &::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      border-radius: 8px;
      border: 2px solid #47475b;
      top: -14px;
      left: 14px;
      z-index: -2;
      background-color: ${themes.colors.backgroundDark};
    }
  `}
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${BOX_RADIUS}px;
  background-color: ${themes.colors.backgroundLight};
  border: ${(props) => (props.control ? `1px solid ${themes.colors.purple}` : null)};
`;

const Text = styled.div`
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
  word-break: break-all;
  color: white;
`;

const PlaceholderIcon = styled(Icon)`
  color: ${themes.colors.white};
`;

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: ${BOX_RADIUS}px;
  background-color: ${themes.colors.backgroundLight};
  border: ${(props) => (props.control ? `1px solid ${themes.colors.purple}` : null)};
`;

const IconWrapper = styled.div`
  width: 250px;
  color: ${themes.colors.white};
`;

export default UploadImage;
